// @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "../src/theme.scss";

body {
  height: 100vh;
  margin: unset;
  font-family: Roboto;
}

:root {
  --primary-color: #ff9e29;
}

::-webkit-scrollbar {
  width: 4px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

div {
  box-sizing: border-box;
}

.mdc-checkbox__native-control:checked~.mdc-checkbox__background .mdc-checkbox__checkmark-path {
  color: white;
}

.sidebar-main-container {
  .mat-expansion-indicator::after {
    color: #a2a3a4;
  }

  .mat-expansion-panel-body {
    padding: unset;
    margin-top: 13px;
  }
}

.color-picker {
  width: 64px;

  mat-form-field {
    width: 100%;

    .mat-mdc-text-field-wrapper {
      padding: unset;
    }

    input {
      padding: 8px 14px;
    }
  }
}

/**
Angular material components overriding
*/
mat-form-field .mat-mdc-text-field-wrapper {
  height: 100%;
}

.mdc-floating-label {
  top: 1.4em !important;
}

.mat-mdc-text-field-wrapper {
  height: 100%;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: unset;
}

app-global-variables,
app-cms-drawing-details,
app-cms-offer-details {
  app-background-input {
    width: 100%;
  }

  .loader {
    .background-input-content {
      width: 100%;

      .background-input-wrapper {
        width: 100%;
      }

      .file-uploader {
        width: 100%;

        .dropzone {
          height: 80px;
        }
      }
    }

    .uploaded-file {
      width: 100%;
    }
  }

  .background-input-content {
    width: 100%;

    .control-title {
      width: 15% !important;
    }

    .background-input-wrapper {
      width: 85%;
    }

    .file-uploader {
      width: 100%;
    }
  }

  mat-form-field {
    height: 48px;

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
      padding-top: 12px;
      padding-bottom: 0px;
    }
  }
}

app-builder {
  .main-builder-nav {
    mat-tab-header {
      background: white;
      width: 30%;
      height: 64px;
      padding: 0px 24px;
    }

    .mdc-tab {
      height: 64px !important;
    }

    .mat-mdc-tab-body-wrapper {
      height: calc(100vh - 113px);
    }
  }
}

app-template-dialog {
  mat-tab-header {
    width: 65%;
    margin-bottom: 24px;
  }

  .mat-mdc-tab-body-content {
    padding-top: 12px;
    padding-bottom: 8px;
  }
}

app-redemption-template-configurations {
  mat-tab-header {
    width: 100%;
    margin-bottom: 24px;
  }
}

app-welcome-message-dialog {
  mat-form-field {
    height: 48px;

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
      padding-top: 12px;
      padding-bottom: 0px;
    }

    .mat-mdc-form-field-flex {
      height: 48px;
    }
  }
}

mat-form-field {
  height: 48px;

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 12px;
    padding-bottom: 0px;
  }

  .mat-mdc-form-field-flex {
    height: 48px;
  }
}

app-icons-picker {
  mat-form-field:not(.color-form-field) {
    height: 48px;

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
      padding-top: 12px;
      padding-bottom: 0px;
    }

    .mat-mdc-form-field-flex {
      height: 48px;
    }
  }
}

app-media-manager,
app-sidecar,
app-chs-image-loop {
  mat-tab-header {
    height: 64px;
    padding: 0px 24px;
  }

  .mdc-tab {
    height: 64px !important;
  }

  .mat-mdc-tab-body-wrapper {
    height: calc(100vh - 113px);
  }

  .background-input-content {
    height: 100%;
    width: 100%;

    .background-input-wrapper {
      height: 100%;
      width: 100%;

      .file-uploader {
        height: 100%;
        width: 100%;

        .dropzone-wrapper {
          height: 100%;
          width: 100%;

          .dropzone {
            width: 100%;
            height: 100%;

            .file-upload-info {
              flex-direction: column;
              justify-content: center;
              gap: 24px;

              mat-icon {
                margin-right: 0px;
              }

              .upload-text {
                margin-right: 0px;
              }
            }
          }
        }
      }
    }
  }
}

app-data-point-dialog,
app-additional-info-dialog,
app-tier-level-card-dialog,
app-tier-level-data-point-dialog,
app-tiers-configuration-dialog-component,
app-page-link-dialog,
app-sign-out-dialog,
app-template-dialog,
app-logout-timer-dialog {
  .background-input-wrapper {
    width: 100% !important;

    .file-uploader {
      width: 100%;
    }

    .file-upload-info {
      mat-icon {
        margin-right: 10px;
      }

      .upload-text {
        margin-right: 30px;
      }
    }
  }
}

app-template-dialog {
  .mat-mdc-tab-body-content {
    overflow: hidden;
  }
}

app-tier-levels {
  app-background-input {
    width: 100%;

    .background-input-wrapper {
      width: 100% !important;
    }

    .file-uploader {
      width: 100%;
    }

    .dropzone {
      height: 80px !important;
    }

    .uploaded-file {
      width: 100% !important;

      mat-icon {
        margin-right: 12px !important;
      }
    }
  }
}

app-tier-level-data-point-dialog {
  .uploaded-file {
    width: 100% !important;
  }

  .image-info-container {
    width: 80%;
  }

  .image-name {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
}

app-components-wrapper {
  .component-container {
    mat-form-field:not(.color-form-field) {
      height: 48px;

      .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
        padding-top: 12px;
        padding-bottom: 0px;
      }
    }
  }

  .main-builder-nav {
    mat-tab-header {
      background: white;
      height: 64px;
      // padding: 0px 24px;
    }

    mat-tab-body {
      padding: 24px;
    }

    .mdc-tab {
      height: 64px !important;
    }

    .mat-mdc-tab-body-wrapper {
      height: calc(100vh - 113px);
    }
  }

  .background-input-content {
    .control-title {
      width: 20% !important;
    }

    .background-input-wrapper {
      width: 80%;
    }

    .file-uploader {
      width: 100%;
    }
  }
}

.mat-mdc-snack-bar-container {
  &.errorSnack {
    --mdc-snackbar-container-color: #e05252;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

app-template-dialog {
  mat-tab-group.hidden-menu {
    mat-tab-header {
      display: none !important;
    }
  }
}

.ofc-active-link {
  .mdc-button__ripple {
    border-bottom: 2px solid;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

app-data-point-list {
  .mat-mdc-menu-panel.mat-mdc-menu-panel {
    max-width: 24vw !important;
  }
}